import './App.css';


import { Routes, Route, Outlet, Link } from "react-router-dom";
import Home from './routes/Home';
import Forum from './routes/Forum';
import Register from './routes/Register';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Header />}>
      <Route index element={<Home />} />
      <Route path="register/:forumId" element={<Register />} />
        <Route path="forum/:forumId" element={<Forum />} />
        {/* Using path="*"" means "match anything", so this route
              acts like a catch-all for URLs that we don't have explicit
              routes for. */}
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}

function Header() {
  return (
    <div className="max-w-lg mx-auto">
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}
      {/* <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
        </ul>
      </nav>
      <hr />
      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <Outlet />
    </div>
  );
}

function NoMatch() {
  return (
    <div className="flex flex-1 flex-col items-center">
      <h1 className="text-3xl">Nothing to see here!</h1>
      <p>
        <Link className="underline italic" to="/">About PhysicalForum</Link>
      </p>
    </div>
  );
}

export default App;

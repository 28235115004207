import { useEffect, useState } from 'react';
import Modal from 'react-awesome-modal';


function Home() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        const timer = setTimeout(() => {
            setIsPopupOpen(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="max-w-lg mx-auto p-6">
            <h1 className="text-3xl font-semibold mb-4">About PhysicalForum</h1>
        
            <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">Welcome to PhysicalForum</h2>
            <p className="text-lg text-gray-700">
                PhysicalForum bridges the physical and digital worlds using QR codes found in physical locations. By scanning these codes, you can engage in discussions, share ideas, ask questions, and connect with others who scan the same QR code.
            </p>
            </section>
        
            <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">Unique Forums for Unique Locations</h2>
            <p className="text-lg text-gray-700">
                Each QR code is tethered to a completely unique forum. For instance, scanning a code in a library brings you to a forum connected only to that code in that library. Scanning another code, even within the same building, will bring you to a completely different forum.
            </p>
            <p className="text-lg text-gray-700">
                Forum names may not be unique, but forum identifiers are. A unique forum identifier is found at the top of every forum, starting with "@". This should help you confirm that you're looking at the forum you intend to.
            </p>
            </section>

            <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">Where can I find PhysicalForums?</h2>
            <p className="text-lg text-gray-700">
                PhysicalForums can be found just about anywhere. That's the beauty. If you'd like to participate, but don't know where any forums are or what they look like, follow us on Instagram to stay in the loop (<a className="text-blue-500" href="https://www.instagram.com/physical.forum/" rel="noreferrer">@physical.forum</a>), and keep your eyes peeled 👀
            </p>
            </section>
        
            <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">Basic Rules for Participation</h2>
            <p className="text-lg text-gray-700">
                PhysicalForum requires no account creation or personal details. Users can post about anything, adhering to basic rules:
            </p>
            <ul className="list-disc pl-6 mb-4 text-lg text-gray-700">
                <li>Respect others' opinions and beliefs</li>
                <li>Avoid posting illegal content or advocating for illegal activities</li>
                <li>Refrain from sharing personally identifying information about yourself or others</li>
                <li>Do not engage in harassment, bullying, or hate speech of any kind</li>
                <li>Keep discussions civil and constructive</li>
                <li>Do not spam or flood the forum with irrelevant or repetitive content</li>
                <li>Respect the privacy of others and refrain from sharing private conversations without consent</li>
                <li>Report any abusive or inappropriate behavior</li>
                <li>Follow the guidelines and instructions provided by the PhysicalForum team</li>
            </ul>
            </section>
        
            <section className="mb-6">
            <h2 className="text-xl font-semibold mb-2">Have Fun!</h2>
            <p className="text-lg text-gray-700">
                Enjoy your time on PhysicalForum and engage in meaningful discussions. 
            </p>
            </section>
        
            <section>
            <h2 className="text-xl font-semibold mb-2">Contact Us</h2>
            <p className="text-lg text-gray-700">
                If you have any questions or concerns, please reach out to support@physicalforum.com.
            </p>
            </section>

            {/* IG Popup */}
            {isPopupOpen && (
                <div className="fixed bottom-0 inset-x-0 px-1 pb-6 flex items-center justify-center">
                    <div className="max-w-lg w-full p-6 bg-gray-800 rounded-lg shadow-lg hover:shadow-xl relative" style={{ width: 'calc(max-w-lg - 4px)', maxWidth: 'calc(max-w-lg - 4px)' }}>
                        <div className="flex justify-between items-center">
                            <h2 className="text-lg font-semibold mb-2 text-white">Follow us on Instagram</h2>
                            <button
                                className="text-gray-300 hover:text-gray-400"
                                onClick={() => setIsPopupOpen(false)}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div className="instagram-popup">
                            <p className="text-gray-300 mb-4">@physical.forum</p>
                            <a
                                href="https://www.instagram.com/physical.forum/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="instagram-link bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200"
                            >
                                Open Page
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
      );
}

export default Home;
import { useEffect, useRef, useState } from 'react';
import { Link, Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { Button, Input } from '@aws-amplify/ui-react';
import { get, post } from 'aws-amplify/api';
import { Discuss } from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';

function ForumRegistration() {
    const navigate = useNavigate();
    let { forumId } = useParams();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        validateId();
    }, [forumId]);

    const validateId = async () => {
        setIsLoading(true);
        try {
            const getForumOperation = get({
            apiName: 'physicalforumApi',
            path: `/forum/${forumId}`,
            });
            const { body } = await getForumOperation.response;
            const response = await body.json();
            console.log('GET forum call succeeded.  Re-routing to forum page');
            navigate(`/forum/${forumId}`);
        } catch (e) {
            if (e.response.statusCode !== 469) {
                navigate("/notfound");            
            }
        }
        setIsLoading(false);
    }

    async function registerForum(forumName) {
        setIsLoading(true);
        try {
          const registerForumOperation = post({
            apiName: 'physicalforumApi',
            path: `/forum/${forumId}`,
            options: {
                body: { forumName }
              }
          });
          const { body } = await registerForumOperation.response;
          const response = await body.json();
          console.log('Successfully registered forum');
          console.log(response);
          // Navigate to the newly registered forum
          navigate(`/forum/${forumId}`);
        } catch (e) {
            alert('Failed to register forum. Please try again.');
            console.log('Failed to register forum: ', JSON.parse(e.response.body));
        }
        setIsLoading(false);
    } 

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const forumName = formData.get('forumName');
        registerForum(forumName);
        e.target.reset();
    };

    return (
        <div className="max-w-lg mx-auto">
            {isLoading && 
                <div className="flex flex-1 h-screen justify-center align-middle items-center">
                    <Discuss
                        visible={isLoading}
                        height="80"
                        width="80"
                        ariaLabel="discuss-loading"
                        wrapperStyle={{}}
                        wrapperClass="discuss-wrapper"
                        color="#fff"
                        backgroundColor="#fff"
                    />
                </div>
            }
            {!isLoading &&
                <div className="max-w-lg mx-auto bg-gray-100 rounded-lg shadow-md">
                    <div className="sticky top-0 bg-white p-6 border-b border-gray-200">
                        <h1 className="text-3xl font-semibold">Forum Registration</h1>
                        <Link className="underline text-sm text-red-400" to="/">What is this?</Link>
                        <p className="text-gray-600 mt-2">You've found an unregistered forum! To register the forum, please provide the information requested below. Worth noting, once a forum has been registered, the below information cannot be altered, and you will only be able to interact with the forum as a user. There is no concept of moderator or forum owner.</p>
                    </div>
                    <form onSubmit={handleSubmit} className="p-6 bg-white">
                        <div>
                            <label htmlFor="forumIdentifier" className="block text-sm font-medium text-gray-700">Forum Identifier</label>
                            <input type="text" id="forumIdentifier" name="forumIdentifier" value={'@'.concat(forumId)} readOnly disabled={true} className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500 mb-2 bg-gray-200" />
                        </div>
                        <div className="pb-4">
                            <label htmlFor="forumName" className="block text-sm font-medium text-gray-700">Forum Name</label>
                            <input type="text" id="forumName" name="forumName" placeholder="Enter forum name" required maxLength="50" className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500 mb-2" />
                        </div>
                        <button 
                            type="submit" 
                            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200"
                            disabled={isLoading}
                        >
                            Register Forum
                        </button>
                    </form>
                </div>
            }
        </div>
    );
}

export default ForumRegistration;

import { useEffect, useRef, useState } from 'react';
import { Link, Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { Button, Input } from '@aws-amplify/ui-react'
import { get, post } from 'aws-amplify/api';
import moment from 'moment';
import { Discuss } from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';


const MOMENT_DATE_FORMAT = 'MM/DD/YYYY h:mm a';

function Forum() {
    const navigate = useNavigate();
    let { forumId } = useParams();
    const [activeButtonRefresh, setActiveButtonRefresh] = useState(false);
    const [forum, setForum] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      if (forum === undefined) {
        console.log('loading forum: ' + forumId);
        fetchForum(true);
      }
    }, [forum, forumId]);
  
    async function fetchForum(shouldAnimateLoad) {
        shouldAnimateLoad ? setIsLoading(true) : setIsLoading(false);
        try {
            const getForumOperation = get({
            apiName: 'physicalforumApi',
            path: `/forum/${forumId}`,
            });
            const { body } = await getForumOperation.response;
            const response = await body.json();
            console.log('GET call succeeded');
            setForum(response);
            console.log(response);
            setIsLoading(false);
        } catch (e) {
            console.log('GET call failed: ', JSON.parse(e.response.body));
            if (e.response.statusCode === 469) {
                navigate(`/register/${forumId}`);
            }
            else {
                navigate("/notfound");
            }
        }
        setIsLoading(false);
    }

    async function postComment(comment) {
        setIsLoading(true);
        try {
          const getForumOperation = post({
            apiName: 'physicalforumApi',
            path: `/forum/${forumId}/post`,
            options: {
                body: comment
              }
          });
          const { body } = await getForumOperation.response;
          const response = await body.json();
          console.log('POST call succeeded');
          console.log(response);
          // Refresh the forum after posting a new comment
          fetchForum(true);
        } catch (e) {
          console.log('POST call failed: ', JSON.parse(e.response.body));
        }
        setIsLoading(false);
    } 

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const comment = {
            alias: formData.get('alias'),
            content: formData.get('content'),
        };
        postComment(comment);
        e.target.reset();
    };

    // Function to scroll to the bottom of the page
    const handleRefreshButtonPress = async () => {
        setActiveButtonRefresh(true);
        window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
        });
        await fetchForum(false);
        setActiveButtonRefresh(false);
    };

    function StartAtBottomComponent(){
        const elementRef = useRef();
        useEffect(() => elementRef.current.scrollIntoView());
        return <div ref={elementRef} />;
      };

    return (
        <div className="max-w-lg mx-auto">
            {isLoading && 
                <div className="flex flex-1 h-screen justify-center align-middle items-center">
                    <Discuss
                        visible={isLoading}
                        height="80"
                        width="80"
                        ariaLabel="discuss-loading"
                        wrapperStyle={{}}
                        wrapperClass="discuss-wrapper"
                        color="#fff"
                        backgroundColor="#fff"
                    />
                </div>
            }
            {forum !== undefined && !isLoading && 
                <div className="max-w-lg mx-auto bg-gray-100 rounded-lg shadow-md">
                    <div className="sticky top-0 bg-white p-6 border-b border-gray-200">
                        <h1 className="text-3xl font-semibold">{forum.forumName}</h1>
                        <p className="italic">@{forum.forumId}</p>
                        <Link className="underline text-sm text-red-400" to="/">What is this?</Link>
                    </div>
                    <div className="p-6">
                        {forum.posts.map((post, idx) => (
                            <div key={idx} className="max-w-lg mx-auto mb-8 border-b pb-8">
                                <div className="flex flex-row justify-between">
                                    <div className="flex-1 break-all text-gray-600 font-semibold">{post.alias}</div>
                                    <div className="flex-1 text-gray-400 text-right italic">{moment.utc(post.createdAt).local().format(MOMENT_DATE_FORMAT)}</div>
                                </div>
                                <p className="flex-wrap break-words text-gray-700">{post.content}</p>
                            </div>
                        ))}
                    </div>
                    <button 
                        className={activeButtonRefresh
                            ? "fixed bottom-8 right-8 bg-red-500 text-white px-4 py-2 rounded-full shadow-md hover:bg-red-600 transition duration-200"
                            : "fixed bottom-8 right-8 bg-blue-500 text-white px-4 py-2 rounded-full shadow-md hover:bg-blue-600 transition duration-200"
                        }
                        onClick={handleRefreshButtonPress}
                    >
                        <FontAwesomeIcon icon={faArrowsRotate} style={{ lineHeight: 1, color: '#FFF' }} />

                    </button>
                    <form onSubmit={handleSubmit} className="p-6 bg-white">
                        <input type="text" id="alias" name="alias" placeholder="Your Alias" required maxLength="20" className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500 mb-2" />
                        <textarea id="content" name="content" placeholder="Your Comment" required maxLength="300" className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500 mb-2"></textarea>
                        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">Post</button>
                    </form>
                </div>
            }
            <StartAtBottomComponent />
        </div>
    );
}

export default Forum;